//@vendor
const Immutable = require('immutable');
//@constants
const { DATE_FORMAT_API } = require('constants/index');
const actionTypes = require('constants/actionTypes');

const moment = require('moment');
const today = moment().startOf('day');
const {
    BROKER_ORDERS_AND_MOVEMENTS_FUND_MOVEMENTS_CONSOLIDATED,
  } = require('constants/index');

const initialState = Immutable.fromJS({
    accessFrom: '',
    selectedOption: null,
    selectedContract: null,
    stateOperation: BROKER_ORDERS_AND_MOVEMENTS_FUND_MOVEMENTS_CONSOLIDATED,
    isCheckContributions: false,
    isCheckSubscription: false,
    isCheckReimbursement: false,
    isCheckTransfer: false,
    dateForFilter: {
        selected: today,
        from: moment().startOf('day').year(2005).format(DATE_FORMAT_API),
        to: today.format(DATE_FORMAT_API)
    }
});

function brokerOrdersAndMovements(state = initialState, action) {
    switch (action.type) {
        case actionTypes.BROKER_ORDERS_AND_MOVEMENTS_SET_ACCESS_FROM:
            return state.merge({
                accessFrom: action.payload.type
            });
        case actionTypes.BROKER_ORDERS_AND_MOVEMENTS_SELECTED_OPTION_CHANGED:
            return state.merge({
                selectedOption: action.payload.selectedOption
            });
        case actionTypes.BROKER_ORDERS_AND_MOVEMENTS_SET_CONTRACT_NUMBER:
            return state.merge({
                selectedContract: action.payload.selectedContract
            });
        case actionTypes.BROKER_ORDERS_AND_MOVEMENTS_RESET:
            return initialState;
        case actionTypes.BROKER_ORDERS_AND_MOVEMENTS_SET_STATE_OPERATIONS_FILTER:
            return state.merge({
                stateOperation: action.payload
            })
        case actionTypes.BROKER_ORDERS_AND_MOVEMENTS_SET_IS_CHECK_CONTRIBUTIONS:
            return state.merge({
                isCheckContributions: action.payload
            })
        case actionTypes.BROKER_ORDERS_AND_MOVEMENTS_SET_IS_CHECK_SUBSCRIPTION:
            return state.merge({
                isCheckSubscription: action.payload
            })
        case actionTypes.BROKER_ORDERS_AND_MOVEMENTS_SET_IS_CHECK_REIMBURSEMENT:
            return state.merge({
                isCheckReimbursement: action.payload
            })
        case actionTypes.BROKER_ORDERS_AND_MOVEMENTS_SET_IS_CHECK_TRANSFER:
            return state.merge({
                isCheckTransfer: action.payload
            })
        case actionTypes.BROKER_ORDERS_AND_MOVEMENTS_SET__MAX_DATE:
            return state.setIn(['dateForFilter', 'to'], action.payload)
        case actionTypes.BROKER_ORDERS_AND_MOVEMENTS_SET__MIN_DATE:
            return state.setIn(['dateForFilter', 'from'], action.payload)
        default:
            return state;
    }
}

module.exports = brokerOrdersAndMovements;
